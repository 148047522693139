import ImageGallery from '../components/Home/ImageGallery';
import TypeWriter from '../components/test/TypeWriter';

export const Test = () => {
    return (
        <div
            // style={{
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
            // width: '100vw',
            // height: '100vh'}}
        >
            <ImageGallery />
            <TypeWriter />
        </div>
    );
}

export  default Test;